import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue")
  },
  {
    path: "/members",
    name: "Members",
    component: () => import("../views/Members.vue")
  },
  {
    path: "/apparatus",
    name: "Apparatus",
    component: () => import("../views/Apparatus.vue")
  },
  {
    path: "/recruitment",
    name: "Recruitment",
    component: () => import("../views/Recruitment.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/donate",
    name: "Donate",
    component: () => import("../views/Donate.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
