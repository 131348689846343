<template>
  <div id="app">
    <Header />
    <Navigator />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Navigator from "@/components/Navigator.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Navigator,
    Footer
  }
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}
</style>
